<div class="events-content" *ngIf="events">
  <p *ngIf="events.length == 0" class="no-events">Aktuell gibt es hier noch keine Veranstaltungen. Schau Dir aber gerne schon einmal unseren <a href="../../assets/files/Veranstaltungskalender_25:26.pdf" target="_blank">Veranstaltungskalender 2025/26</a> an.</p>
  <mat-card class="event-card" *ngFor="let event of events">
    <div>
      <img mat-card-image src="{{event.imageSource}}" alt="Logo der Ortsgemeinde Merxheim">
    </div>
    <mat-card>
      <mat-card-title>{{event.weekDay}}, {{event.date}}</mat-card-title>
      <mat-card-subtitle>{{event.time}}</mat-card-subtitle>
    </mat-card>
    <mat-card-title>{{event.title}}</mat-card-title>
    <mat-card-subtitle>{{event.subtitle}}</mat-card-subtitle>
    <mat-card-content>
      <p [innerHtml]="event.description"></p>
    </mat-card-content>
    <mat-card-footer>
      <mat-divider inset></mat-divider>
      <p><mat-icon>location_on</mat-icon>{{event.location}}</p>
    </mat-card-footer>
  </mat-card>
</div>
